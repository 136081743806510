import { fetchAPI } from "../../../../config/axios";
import { TAction, TDispatch } from "../../../types";
import {
  ConfigureRoleMgtAction,
  ConfigureRoleMgtActionType,
  ConfigureRoleMgtModel,
} from "./types";

export const ConfigureRoleMgtDefault: ConfigureRoleMgtModel = {
  main: {
    data: [],
    total: 0,
  },
};

// // NANTI HAPUS
// const DUMMY_DATA: ConfigureRoleMgtModel['main']['data'] = [
//   {
//     role: "Administrator",
//     define_role: "Risk Management",
//     permissions: ['create', 'edit', 'read'],
//     status: 'active',
//   },
//   {
//     role: "Member",
//     define_role: "Risk Management",
//     permissions: ['read'],
//     status: 'active',
//   },
// ];
// NANTI HAPUS

export const ConfigureRoleMgtReducer = (
  state: ConfigureRoleMgtModel = ConfigureRoleMgtDefault,
  action: ConfigureRoleMgtAction
): ConfigureRoleMgtModel => {
  switch (action.type) {
    case ConfigureRoleMgtActionType.GetMainRoleMgt:
      return { ...state, main: action.data };

    default:
      return { ...state };
  }
};

export const ConfigureRoleMgtCommand = {
  GetMainRoleData: (): TAction<ConfigureRoleMgtAction, void> => {
    return (dispatch: TDispatch<ConfigureRoleMgtAction>) => {
      return fetchAPI().get('/api/v1/roles').then((response) => {
        dispatch({
          data: {
            data: response.data.data.data,
            total: response.data.data.total,
          },
          type: ConfigureRoleMgtActionType.GetMainRoleMgt,
        });
      });
    };
  },
  DeleteRoleData: () => {
      return fetchAPI().get('/api/v1/roles').then((response) => {
        return 'ok'
      });
  },
};
