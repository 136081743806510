import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import { fetchAPI } from "./config/axios";
import { notify } from "./utils";

interface Abilities {
}

interface User {
  ulid: string;
  name: string;
  email: string;
  status: string;
}

interface UserInformation {
  token: string;
  abilities: Abilities[];
  user: User;
}

interface FormData {
  email: string;
  password: string;
}


interface AuthContextProps {
  isAuthenticated: boolean;
  userinfo: UserInformation;
  login: (formData: FormData) => void;
  logout: () => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [userinfo, setUserInfo] = useState<UserInformation>({
    token: "",
    abilities: [],
    user: {
      ulid: "",
      name: "",
      email: "",
      status: "",
    }
  });

  useEffect(() => {
    const parsedUserData = localStorage.getItem('userinfo');
    if (parsedUserData) {
      let userlogin = parsedUserData ? JSON.parse(parsedUserData) : {};
      setUserInfo({
        token: userlogin.token,
        abilities: userlogin.abilities,
        user: {
          ulid: userlogin.user.ulid,
          name: userlogin.user.name,
          email: userlogin.user.email,
          status: userlogin.user.status,
        }
      });
    }else {
      // logout();
    }
  }, []);

  const login = async (formData) => {
    await handleLogin(formData);
  };

  const handleLogin = async (formData) => {
    try {
        const response = await fetchAPI().post('/v1/auth/login', {
          ...formData,
          device_name: 'desktop'
        }).then((res) => {
          const response = res.data.data
          localStorage.setItem('userinfo', JSON.stringify(response));
          /* Set User Login Credentials */
          setUserInfo({
            token: response.token,
            abilities: response.abilities,
            user: {
              ulid: response.user.ulid,
              name: response.user.name,
              email: response.user.email,
              status: response.user.status,
            }
          })

          notify.success({
            title: "Success",
            description: "Login Verified",
          });
          setTimeout(() => {
            window.location.href = "/";
          }, 200)
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            const validationError = error.response.data;
      
            for (let key in validationError.data) {
              notify.error({
                title: validationError.error,
                description: validationError.data[key][0],
              });
            }
          } else {
            notify.error({
              title: "Error",
              description: error.message,
            });
          }
        })
      } catch (error) {
        // Handle login errors
        console.error('[][][] Login error:', error);
      }
  }

  const logout = async () => {
    try {
      const response = await fetchAPI().post('/api/v1/user/logout').then((response) => {
        notify.success({
          title: "Success",
          description: "Success Logout",
        });
        localStorage.removeItem('userinfo');
        setTimeout(() => {
          window.location.href = "/login";
        }, 200)
      }).catch((error) => {
        notify.error({
          title: "Error",
          description: error.message,
        });
      })
    } catch (error) {
      // Handle logout errors
      console.error('[][][] Login error:', error);
    }
  };

  const authContextValue: AuthContextProps = {
    isAuthenticated,
    userinfo,
    login,
    logout,
  };

  return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
};