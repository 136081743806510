import React, { lazy } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom'
import reportWebVitals from './reportWebVitals';
import { StoreProvider } from './redux/store';

import { ToastContainer } from 'react-toastify';
import { AuthProvider } from './AuthContext';


import 'react-perfect-scrollbar/dist/css/styles.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';
import './index.css';

const LazyApp = lazy(() => import("./App"));
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <StoreProvider>
        <BrowserRouter>
          <LazyApp />
          <ToastContainer
            hideProgressBar={true}
            newestOnTop={true}
            pauseOnFocusLoss={false}
            pauseOnHover={true}
          />
        </BrowserRouter>
      </StoreProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
