import { TAction, TDispatch } from "../../../types";
import {
  ConfigureAllUserDataModel,
  ConfigureUserAction,
  ConfigureUserActionType,
  ConfigureUserModel,
} from "./types";
import { fetchAPI } from "../../../../config/axios";

export const ConfigureUserDefault: ConfigureUserModel = {
  riskData: {
    data: [],
    total: 0,
  },
  allUser: {
    data: [],
    total: 0,
  },
};

// NANTI HAPUS
const DUMMY_DATA: ConfigureUserModel["riskData"]["data"] = [
  {
    name: "Jane Cooper",
    username: "jane_cooper_12",
    email: "jane.cooper@gmail.com",
    unit: "Risk Management",
    password: "",
    position: "Officer of Risk Owner",
    role: ["Administrator", "Risk VP"],
  },
  {
    name: "Jane Cooper",
    username: "jane_cooper_12",
    email: "cody.fisher@example.com",
    unit: "Risk Management",
    password: "",
    position: "Officer of Risk Owner",
    role: ["Risk VP"],
  },
  {
    name: "Jane Cooper",
    username: "jane_cooper_12",
    email: "esther.howard@example.com",
    unit: "Risk Management",
    password: "",
    position: "Officer of Risk Owner",
    role: ["Administrator", "Risk VP"],
  },
  {
    name: "Jane Cooper",
    username: "jane_cooper_12",
    email: "jenny.wilson@example.com",
    unit: "Risk Management",
    password: "",
    position: "Officer of Risk Owner",
    role: ["Risk VP"],
  },
  {
    name: "Jane Cooper",
    username: "jane_cooper_12",
    email: " kristin.watson@example.com",
    unit: "Risk Management",
    password: "",
    position: "Officer of Risk Owner",
    role: ["Risk VP"],
  },
  {
    name: "Jane Cooper",
    username: "jane_cooper_12",
    email: " cameron.williamson@example.com",
    unit: "Risk Management",
    password: "",
    position: "Officer of Risk Owner",
    role: ["Administrator", "Risk VP"],
  },
  {
    name: "Jane Cooper",
    username: "jane_cooper_12",
    email: " cameron.williamson@example.com",
    unit: "Risk Management",
    password: "",
    position: "Officer of Risk Owner",
    role: ["Administrator", "Risk VP"],
  },
  {
    name: "Jane Cooper",
    username: "jane_cooper_12",
    email: " cameron.williamson@example.com",
    unit: "Risk Management",
    password: "",
    position: "Officer of Risk Owner",
    role: ["Risk VP"],
  },
  {
    name: "Jane Cooper",
    username: "jane_cooper_12",
    email: " cameron.williamson@example.com",
    unit: "Risk Management",
    password: "",
    position: "Officer of Risk Owner",
    role: ["Administrator", "Risk VP"],
  },
  {
    name: "Jane Cooper",
    username: "jane_cooper_12",
    email: " cameron.williamson@example.com",
    unit: "Risk Management",
    password: "",
    position: "Officer of Risk Owner",
    role: ["Administrator", "Risk VP"],
  },
];
// NANTI HAPUS

export const ConfigureUserReducer = (
  state: ConfigureUserModel = ConfigureUserDefault,
  action: ConfigureUserAction
): ConfigureUserModel => {
  switch (action.type) {
    case ConfigureUserActionType.GetUserRiskData:
      return { ...state, riskData: action.data };
    case ConfigureUserActionType.GetAllUser:
      return { ...state, allUser: action.data };

    default:
      return { ...state };
  }
};

export const ConfigureUserCommand = {
  GetUserRiskData: (params: unknown): TAction<ConfigureUserAction, void> => {
    return (dispatch: TDispatch<ConfigureUserAction>) => {
      return fetchAPI().get('/api/v1/role/users', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data.data,
            total: response.data.data.total,
          },
          type: ConfigureUserActionType.GetUserRiskData,
        });
      });
    };
  },
  GetAllUserData: (params: unknown): TAction<ConfigureUserAction, void> => {
    return (dispatch: TDispatch<ConfigureUserAction>) => {
      return fetchAPI().get('/api/v1/users', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data.data,
            total: response.data.data.total,
          },
          type: ConfigureUserActionType.GetAllUser,
        });
      });
    };
  },
};
