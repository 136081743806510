import { TAction, TDispatch } from "../../../types";
import {
  KriTresholdAction,
  KriTresholdActionType,
  KriTresholdModel,
} from "./types";

export const KriTresholdDefault: KriTresholdModel = {
  main: {
    data: [],
    total: 0,
  },
};

// NANTI HAPUS
const DUMMY_DATA: KriTresholdModel["main"]["data"] = [
  {
    name: "KRI 2022",
    total_kri: 10,
  },
  {
    name: "KRI 2023",
    total_kri: 8,
  },
  {
    name: "KRI 2024",
    total_kri: 5,
  },
  {
    name: "KRI 2025",
    total_kri: 4,
  },
  {
    name: "KRI 2026",
    total_kri: 8,
  },
  {
    name: "KRI 2027",
    total_kri: 9,
  },
];
// NANTI HAPUS

export const KriTresholdReducer = (
  state: KriTresholdModel = KriTresholdDefault,
  action: KriTresholdAction
): KriTresholdModel => {
  switch (action.type) {
    case KriTresholdActionType.GetKriTresholdMainData:
      return { ...state, main: action.data };

    default:
      return { ...state };
  }
};

export const KriTresholdCommand = {
  GetMainData: (): TAction<KriTresholdAction, void> => {
    return (dispatch: TDispatch<KriTresholdAction>) => {
      return Promise.resolve().then(() => {
        dispatch({
          data: {
            data: DUMMY_DATA,
            total: 10,
          },
          type: KriTresholdActionType.GetKriTresholdMainData,
        });
      });
    };
  },
};
