export interface ProjectMainDataModel {
  key_1: string;
  period: string;
  title: string;
  description: string;
  status: boolean;
}

export interface ProjectModel {
  main: {
    data: ProjectMainDataModel[];
    total: number;
  };
}

export enum ProjectActionType {
  GetProjectMainData = "⌘➝Parameter Setting➝Project➝GetProjectMainData",
}

export type ProjectAction = {
  data: {
    data: ProjectMainDataModel[];
    total: number;
  };
  type: ProjectActionType.GetProjectMainData;
};
