const ROUTES = {
  auth: {
    login: "/login",
    resetPassword: "/reset-password",
  },
  dashboard: {
    riskOwner: "/dashboard/risk-owner",
  },
  parameterSetting: {
    rcsa: "/parameter-setting/rsca-entity-period",
    assignment: "/parameter-setting/assignment",
    assignmentAction: "/parameter-setting/assignment/:action",
    project: "/parameter-setting/project",
    projectAction: "/parameter-setting/project/:action",
    workflow: "/parameter-setting/workflow",
    workflowEdit: "/parameter-setting/workflow/edit",
    taxonomy: "/parameter-setting/taxonomy",
    kriTreshold: "/parameter-setting/kri-treshold",
    kriTresholdCreateMaster: "/parameter-setting/kri-treshold/create-master",
    kriTresholdDetail: "/parameter-setting/kri-treshold/detail",
    kriTresholdDetailAction: "/parameter-setting/kri-treshold/detail/:action",
    criteria: "/parameter-setting/criteria",
    criteriaEdit: "/parameter-setting/criteria/edit",
    templateMaturity: "/parameter-setting/template-maturity",
    templateMaturityDetail: "/parameter-setting/template-maturity/detail",
  },
  configure: {
    user: "/configure/user",
    settingInterface: "/configure/setting-interface",
    roleManagement: "/configure/role-management",
    userDelegation: "/configure/user-delegation",
    auditTrails: "/configure/audit-trails",
    unitPI: "/configure/unit-pi",
    entity: "/configure/entity",
  },
};

const NAVS = {
  dashboard: {
    parent: "dashboard",
    riskOwner: "dashboard-risk-owner",
  },
  parameterSetting: {
    parent: "parameter-setting",
    rcsa: "parameter-setting-rcsa",
    assignment: "parameter-setting-assignment",
    assignmentAction: "parameter-setting-assignment-action",
    project: "parameter-setting-project",
    projectAction: "parameter-setting-project-action",
    workflow: "parameter-setting-workflow",
    workflowEdit: "parameter-setting-workflow-edit",
    taxonomy: "parameter-setting-taxonomy",
    kriTreshold: "parameter-setting-kri-treshold",
    kriTresholdCreateMaster: "parameter-setting-kri-treshold-create-master",
    kriTresholdDetail: "parameter-setting-kri-treshold-detail",
    kriTresholdDetailAction: "parameter-setting-kri-treshold-detail-action",
    criteria: "parameter-setting-criteria",
    criteriaEdit: "parameter-setting-criteria-edit",
    templateMaturity: "parameter-setting-template-maturity",
    templateMaturityDetail: "parameter-setting-template-maturity-detail",
  },
  configure: {
    parent: "configure",
    user: "configure-user",
    settingInterface: "configure-setting-interface",
    roleManagement: "configure-role-management",
    userDelegation: "configure-user-delegation",
    auditTrails: "configure-audit-trails",
    unitPI: "configure-unit-pi",
    entity: "configure-entity",
  },
};

const DEFAULT_ROUTE = ROUTES.dashboard.riskOwner;

const API_URL = "https://virtue-be.blugrin.ltd";
const UPPER_A = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

export { ROUTES, DEFAULT_ROUTE, NAVS, API_URL, UPPER_A };
