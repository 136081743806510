import clsx from "clsx";
import { ReactNode } from "react";
import { toast } from "react-toastify";

interface NotifyProps {
  title?: ReactNode
  description?: ReactNode
}
const Content = ({ title, description, toastProps }) => {
  return (
    <div>
      <div
        className={clsx("absolute h-full w-1 top-0 left-0", {
          "bg-success": toastProps.type === "success",
          "bg-info": toastProps.type === "info",
          "bg-warning": toastProps.type === "warning",
          "bg-danger": toastProps.type === "error",
        })}
      />
      <div className="text-body-3 text-neutral-900 font-bold">{title}</div>
      <div className="text-body-4 text-neutral-700">{description}</div>
    </div>
  );
};

const notify = {
  success: (params?: NotifyProps) =>
    toast.success(({ toastProps }) => (
      <Content title={params?.title || ''} description={params?.description || ''} toastProps={toastProps} />
    )),
  error: (params?: NotifyProps) =>
    toast.error(({ toastProps }) => (
      <Content title={params?.title || ''} description={params?.description || ''} toastProps={toastProps} />
    ), { autoClose: false }),
  info: (params?: NotifyProps) =>
    toast.info(({ toastProps }) => (
      <Content title={params?.title || ''} description={params?.description || ''} toastProps={toastProps} />
    )),
  warning: (params?: NotifyProps) =>
    toast.warning(({ toastProps }) => (
      <Content title={params?.title || ''} description={params?.description || ''} toastProps={toastProps} />
    )),
};

export { notify };
