import { TAction, TDispatch } from "../../../types";
import {
  AssignmentAction,
  AssignmentActionType,
  AssignmentModel,
} from "./types";

export const AssignmentDefault: AssignmentModel = {
  main: {
    data: [],
    total: 0,
  },
};

// NANTI HAPUS
const DUMMY_DATA: AssignmentModel["main"]["data"] = [
  {
    code: "C-001",
    project: "Project 1",
    entity_group: "Group 1",
    entity: "Group 1",
    workflow: "Group 1",
    status: true,
  },
  {
    code: "C-002",
    project: "Project 2",
    entity_group: "Group 2",
    entity: "Group 2",
    workflow: "Group 2",
    status: true,
  },
  {
    code: "C-003",
    project: "Project 3",
    entity_group: "Group 3",
    entity: "Group 3",
    workflow: "Group 3",
    status: false,
  },
  {
    code: "C-004",
    project: "Project 4",
    entity_group: "Group 4",
    entity: "Group 4",
    workflow: "Group 4",
    status: true,
  },
];
// NANTI HAPUS

export const AssignmentReducer = (
  state: AssignmentModel = AssignmentDefault,
  action: AssignmentAction
): AssignmentModel => {
  switch (action.type) {
    case AssignmentActionType.GetAssignmentMainData:
      return { ...state, main: action.data };

    default:
      return { ...state };
  }
};

export const AssignmentCommand = {
  GetMainData: (): TAction<AssignmentAction, void> => {
    return (dispatch: TDispatch<AssignmentAction>) => {
      return Promise.resolve().then(() => {
        dispatch({
          data: {
            data: DUMMY_DATA,
            total: 20,
          },
          type: AssignmentActionType.GetAssignmentMainData,
        });
      });
    };
  },
};
