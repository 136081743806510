import { TAction, TDispatch } from "../../../types";
import {
  ConfigureSettingAction,
  ConfigureSettingActionType,
  ConfigureSettingMainDataModel,
  ConfigureSettingModel,
} from "./types";
import { fetchAPI } from "../../../../config/axios";

export const ConfigureSettingDefault: ConfigureSettingModel = {
  main: {
    data: [],
  },
};

export const ConfigureSettingReducer = (
  state: ConfigureSettingModel = ConfigureSettingDefault,
  action: ConfigureSettingAction
): ConfigureSettingModel => {
  switch (action.type) {
    case ConfigureSettingActionType.GetSettingMain:
      return { ...state, main: action.data };

    // case ConfigureSettingActionType.UpdateSettingMain:
    //   return { ...state, main: action.data, response: action.response }; // Assuming this updates 'main' data

    // case ConfigureSettingActionType.SetError: // New action type for error handling
    //   return { ...state, error: action.error };

    default:
      return { ...state };
  }
};

export const ConfigureSettingCommand = {
  GetSettingMainData: (params: unknown): TAction<ConfigureSettingAction, void> => {
    return (dispatch: TDispatch<ConfigureSettingAction>) => {
      return fetchAPI().get('/api/v1/setting', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data
          },
          type: ConfigureSettingActionType.GetSettingMain,
        });
      });
    };
  },
  // UpdateSettingMainData: (params: unknown): TAction<ConfigureSettingAction, void> => {
  //   return (dispatch: TDispatch<ConfigureSettingAction>) => {
  //     return fetchAPI().patch('/api/v1/setting', params)
  //       .then((response) => {
  //         console.log('[][][] response', response)
  //         dispatch({
  //           data: response.data.data,
  //           type: ConfigureSettingActionType.UpdateSettingMain,
  //           response: response
  //         });

  //         return response;
  //       })
  //       .catch((error) => {
  //         console.log('[][][] error', error);
  //         dispatch({
  //           type: ConfigureSettingActionType.SetError,
  //           error: error.message || 'An error occurred while updating data',
  //         });

  //         throw error;
  //       });
  //   };
  // },
};
