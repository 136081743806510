export interface ConfigureEntityGroupDataModel {
  id: string;
  ulid: string;
  code: string;
  name: string;
  type: string;
}

export interface ConfigureEntityGroupTypeDataModel {
  type: string;
}

export interface ConfigureEntityPositionDataModel {
  entity_id: string;
  group_ulid: string;
  id: string;
  ulid: string;
  code: string;
  name: string;
  group_unit: string;
  unit: string;
  status: string;
  group?: any;
  entity?: any;
}

export interface ConfigureEntityTreeDataModel {
  id: string;
  ulid: string;
  code: string;
  name: string;
  description: string;
  level: string;
  type: string;
  properties: string;
  status: string;
  parent_ulid: string;
  group_ulid: string;
  children?: any[];
  group?: any;
}

export interface ConfigureEntityDataModel {
  id: string;
  ulid: string;
  code: string;
  name: string;
  group_unit: string;
  unit: string;
  status: string;
}

export interface ConfigureUpdateEntity {
  status: string;
  message: string;
  error?: any;
}

export interface ConfigureEntityModel {
  entity: {
    data: ConfigureEntityDataModel[];
    total: number;
  };
  entityGroup: {
    data: ConfigureEntityGroupDataModel[];
    total: number;
  };
  entityGroupType: {
    data: ConfigureEntityGroupTypeDataModel[];
    total: number;
  };
  entityPosition: {
    data: ConfigureEntityPositionDataModel[];
    total: number;
  };
  entityPositionDetail: {
    data: ConfigureEntityPositionDataModel;
  };
  entityTree: {
    data: ConfigureEntityTreeDataModel[];
    total: number;
  },
  entityChild: {
    data: ConfigureEntityTreeDataModel[];
    total: number;
  },
}

export enum ConfigureEntityActionType {
  GetAllEntity = "⌘➝Configure➝Entity➝GetAllEntity",
  GetAllEntityGroup = "⌘➝Configure➝Entity➝GetAllEntityGroup",
  GetAllEntityGroupType = "⌘➝Configure➝Entity➝GetAllEntityGroupType",
  GetAllEntityPosition = "⌘➝Configure➝Entity➝GetAllEntityPosition",
  GetDetailEntityPosition = "⌘➝Configure➝Entity➝GetDetailEntityPosition",
  GetParentEntityTree = "⌘➝Configure➝Entity➝GetParentEntityTree",
  GetChildEntityTree = "⌘➝Configure➝Entity➝GetChildEntityTree",
  UpdateEntity = "⌘➝Configure➝Entity➝UpdateEntity",
  UpdateEntitySuccess = "⌘➝Configure➝Entity➝UpdateEntitySuccess",
  UpdateEntityError = "⌘➝Configure➝Entity➝UpdateEntityError",
}

export type ConfigureEntityAction = {
  data: {
    data: ConfigureEntityGroupDataModel[];
    total: number;
  };
  type: ConfigureEntityActionType.GetAllEntityGroup;
} | {
  data: {
    data: ConfigureEntityGroupTypeDataModel[];
    total: number,
  };
  type: ConfigureEntityActionType.GetAllEntityGroupType;
} | {
  data: {
    data: ConfigureEntityPositionDataModel[];
    total: number;
  };
  type: ConfigureEntityActionType.GetAllEntityPosition;
} | {
  data: {
    data: ConfigureEntityPositionDataModel;
    total: number;
  };
  type: ConfigureEntityActionType.GetDetailEntityPosition;
}| {
  data: {
    data: ConfigureEntityDataModel[];
    total: number;
  };
  type: ConfigureEntityActionType.GetAllEntity;
} | {
  data: {
    data: ConfigureEntityTreeDataModel[];
    total: number;
  };
  type: ConfigureEntityActionType.GetParentEntityTree;
} | {
  data: {
    data: ConfigureEntityTreeDataModel[];
    total: number;
  };
  type: ConfigureEntityActionType.GetChildEntityTree;
}  | {
  type: ConfigureEntityActionType.UpdateEntitySuccess;
  message: string;
  data: any;
}
| {
  type: ConfigureEntityActionType.UpdateEntityError;
  error: string;
};