import { TAction, TDispatch } from "../../../types";
import {
  ConfigureEntityAction,
  ConfigureEntityActionType,
  ConfigureEntityModel,
} from "./types";
import { fetchAPI } from "../../../../config/axios";

export const ConfigureEntityDefault: ConfigureEntityModel = {
  entity: {
    data: [],
    total: 0,
  },
  entityGroup: {
    data: [],
    total: 0,
  },
  entityGroupType: {
    data: [],
    total: 0,
  },
  entityPosition: {
    data: [],
    total: 0,
  },
  entityPositionDetail: {
    data: {} as any,
  },
  entityTree: {
    data: [],
    total: 0,
  },
  entityChild: {
    data: [],
    total: 0,
  },
};

export const ConfigureEntityReducer = (
  state: ConfigureEntityModel = ConfigureEntityDefault,
  action: ConfigureEntityAction
): ConfigureEntityModel => {
  switch (action.type) {
    case ConfigureEntityActionType.GetAllEntity:
      return { ...state, entity: action.data };
    case ConfigureEntityActionType.GetAllEntityGroup:
      return { ...state, entityGroup: action.data };
    case ConfigureEntityActionType.GetAllEntityGroupType:
      return { ...state, entityGroupType: action.data };
    case ConfigureEntityActionType.GetAllEntityPosition:
      return { ...state, entityPosition: action.data };
    case ConfigureEntityActionType.GetDetailEntityPosition:
      return { ...state, entityPositionDetail: action.data };
    case ConfigureEntityActionType.GetParentEntityTree:
      return { ...state, entityTree: action.data };
    case ConfigureEntityActionType.GetChildEntityTree:
      return { ...state, entityChild: action.data };
    default:
      return { ...state };
  }
};

export const ConfigureEntityCommand = {
  GetAllEntityData: (): TAction<ConfigureEntityAction, void> => {
    return (dispatch: TDispatch<ConfigureEntityAction>) => {
      return fetchAPI().get('/api/v1/entities').then((response) => {
        dispatch({
          data: {
            data: response.data.data.data,
            total: response.data.data.total,
          },
          type: ConfigureEntityActionType.GetAllEntity,
        });
      });
    };
  },
  GetAllEntityGroupData: (params: unknown): TAction<ConfigureEntityAction, void> => {
    return (dispatch: TDispatch<ConfigureEntityAction>) => {
      return fetchAPI().get('/api/v1/groups', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data.data,
            total: response.data.data.total,
          },
          type: ConfigureEntityActionType.GetAllEntityGroup,
        });
      });
    };
  },
  GetAllEntityGroupTypeData: (params: unknown): TAction<ConfigureEntityAction, void> => {
    return (dispatch: TDispatch<ConfigureEntityAction>) => {
      return fetchAPI().get('/api/v1/setting', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data.value.type,
            total: 0,
          },
          type: ConfigureEntityActionType.GetAllEntityGroupType,
        });
      });
    };
  },
  GetAllEntityPosition: (params: unknown): TAction<ConfigureEntityAction, void> => {
    return (dispatch: TDispatch<ConfigureEntityAction>) => {
      return fetchAPI().get('/api/v1/positions', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data.data,
            total: response.data.data.total,
          },
          type: ConfigureEntityActionType.GetAllEntityPosition,
        });
      });
    };
  },
  GetDetailEntityPosition: (params: unknown): TAction<ConfigureEntityAction, void> => {
    return (dispatch: TDispatch<ConfigureEntityAction>) => {
      return fetchAPI().get('/api/v1/position', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data,
            total: 1,
          },
          type: ConfigureEntityActionType.GetDetailEntityPosition,
        });
      });
    };
  },
  GetParentEntityTree: (params: unknown): TAction<ConfigureEntityAction, void> => {
    return (dispatch: TDispatch<ConfigureEntityAction>) => {
      return fetchAPI().get('/api/v1/entity/tree').then((response) => {
        const data = response.data.data;
        dispatch({
          data: {
            data: data,
            total: data.length,
          },
          type: ConfigureEntityActionType.GetParentEntityTree,
        });
      });
    };
  },
  GetChildEntityTree: (params: unknown): TAction<ConfigureEntityAction, void> => {
    return (dispatch: TDispatch<ConfigureEntityAction>) => {
      return fetchAPI().get('/api/v1/entity/tree', { params }).then((response) => {
        const data = response.data.data;
        dispatch({
          data: {
            data: data,
            total: data.length,
          },
          type: ConfigureEntityActionType.GetChildEntityTree,
        });
      });
    };
  },

  UpdateEntity: (body: any): TAction<ConfigureEntityAction, void> => {
    return async (dispatch: TDispatch<ConfigureEntityAction>) => {
      try {
        const response = await fetchAPI().patch('/api/v1/entity', { ...body });
        dispatch({
          type: ConfigureEntityActionType.UpdateEntitySuccess,
          message: 'Entity updated successfully!',
          data: response.data,
        });

        return response.data;
      } catch (error) {
        dispatch({
          type: ConfigureEntityActionType.UpdateEntityError,
          error: 'Failed to update entity',
        });

        throw error;
      }
    };
  },
  ImportEntity: (body: any): TAction<ConfigureEntityAction, void> => {
    return async (dispatch: TDispatch<ConfigureEntityAction>) => {
      try {
        const response = await fetchAPI().post('/api/v1/entity/import/xlsx', body, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        return response.data;
      } catch (error) {
        throw error;
      }
    };
  },
};
