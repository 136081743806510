export interface AuditTrailsActivityLogDataModel {
  time: string;
  name: string;
  description: string;
  caused_by: string;
}
export interface AuditTrailsSystemLogDataModel {
  level: string;
  level_class: string;
  context: string;
  date: string;
  content: string;
  stack: string;
  text: string;
}

export interface ConfigureAuditTrailsModel {
  activityLogs: {
    main: {
      data: AuditTrailsActivityLogDataModel[];
      total: number;
    };
  };
  systemLogs: {
    main: {
      data: AuditTrailsSystemLogDataModel[];
      total: number;
    };
  };
}

export enum ConfigureAuditTrailsActionType {
  GetActivityLogsMain = "⌘➝Configure➝Audit Trails➝GetActivityLogsMain",
  GetSystemLogsMain = "⌘➝Configure➝Audit Trails➝GetSystemLogsMain",
}

export type ConfigureAuditTrailsAction = {
  data: {
    data: AuditTrailsActivityLogDataModel[];
    total: number;
  };
  type: ConfigureAuditTrailsActionType.GetActivityLogsMain;
} | {
  data: {
    data: AuditTrailsSystemLogDataModel[];
    total: number;
  };
  type: ConfigureAuditTrailsActionType.GetSystemLogsMain;
};
