export interface ConfigureRoleMgtDataModel {
  ulid: string;
  role: string;
  name: string;
  title: string;
  define_role: string;
  permissions: string[];
  status: string;
}

export interface ConfigureRoleMgtModel {
  main: {
    data: ConfigureRoleMgtDataModel[];
    total: number;
  };
}

export enum ConfigureRoleMgtActionType {
  GetMainRoleMgt = "⌘➝Configure➝Role Management➝GetMainRoleMgt",
}

export type ConfigureRoleMgtAction = {
  data: {
    data: ConfigureRoleMgtDataModel[];
    total: number;
  };
  type: ConfigureRoleMgtActionType.GetMainRoleMgt;
};
