import { TAction, TDispatch } from "../../../types";
import { WorkflowAction, WorkflowActionType, WorkflowModel } from "./types";

export const WorkflowDefault: WorkflowModel = {
  main: {
    data: [],
    total: 0,
  },
};

// NANTI HAPUS
const DUMMY_DATA: WorkflowModel["main"]["data"] = [
  {
    code: "Code 1",
    name: "RCSA",
    type: "Type 1",
    model: "Model 1",
    description: "Description 1",
    group_code: "Entity 1",
  },
  {
    code: "Code 2",
    name: "LED",
    type: "Type 1",
    model: "Model 1",
    description: "Description 2",
    group_code: "Entity 1",
  },
  {
    code: "Code 1",
    name: "jane_cooper_12",
    type: "Type 1",
    model: "Model 1",
    description: "Description 1",
    group_code: "Entity 1",
  },
  {
    code: "Code 2",
    name: "LED",
    type: "Type 1",
    model: "Model 1",
    description: "Description 2",
    group_code: "Entity 1",
  },
  {
    code: "Code 1",
    name: "jane_cooper_12",
    type: "Type 1",
    model: "Model 1",
    description: "Description 1",
    group_code: "Entity 1",
  },
  {
    code: "Code 2",
    name: "LED",
    type: "Type 1",
    model: "Model 1",
    description: "Description 2",
    group_code: "Entity 1",
  },
];
// NANTI HAPUS

export const WorkflowReducer = (
  state: WorkflowModel = WorkflowDefault,
  action: WorkflowAction
): WorkflowModel => {
  switch (action.type) {
    case WorkflowActionType.GetWorkflowMainData:
      return { ...state, main: action.data };

    default:
      return { ...state };
  }
};

export const WorkflowCommand = {
  GetMainData: (): TAction<WorkflowAction, void> => {
    return (dispatch: TDispatch<WorkflowAction>) => {
      return Promise.resolve().then(() => {
        dispatch({
          data: {
            data: DUMMY_DATA,
            total: 20,
          },
          type: WorkflowActionType.GetWorkflowMainData,
        });
      });
    };
  },
};
