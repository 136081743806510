import { TAction, TDispatch } from "../../../types";
import {
  RCSAEntityPeriodAction,
  RCSAEntityPeriodActionType,
  RCSAEntityPeriodModel,
} from "./types";

export const RCSAEntityPeriodDefault: RCSAEntityPeriodModel = {
  main: {
    data: [],
    total: 0,
  },
};

// NANTI HAPUS
const DUMMY_DATA: RCSAEntityPeriodModel['main']['data'] = [
  {
    groupName: 'East Indies Inc',
    period: '2023',
    startDate: new Date().toString(),
    endDate: new Date().toString(),
    description: 'Q1',
    status: 'active'
  },
  {
    groupName: 'North Indies Inc',
    period: '2023',
    startDate: new Date().toString(),
    endDate: new Date().toString(),
    description: 'Q2',
    status: 'active'
  },
  {
    groupName: 'West Indies Inc',
    period: '2023',
    startDate: new Date().toString(),
    endDate: new Date().toString(),
    description: 'Q3',
    status: 'inactive'
  },
  {
    groupName: 'South Indies Inc',
    period: '2023',
    startDate: new Date().toString(),
    endDate: new Date().toString(),
    description: 'Q4',
    status: 'active'
  },
];
// NANTI HAPUS

export const RCSAEntityPeriodReducer = (
  state: RCSAEntityPeriodModel = RCSAEntityPeriodDefault,
  action: RCSAEntityPeriodAction
): RCSAEntityPeriodModel => {
  switch (action.type) {
    case RCSAEntityPeriodActionType.GetEntityPeriodName:
      return { ...state, main: action.data };

    default:
      return { ...state };
  }
};

export const RCSAEntityPeriodCommand = {
  GetMainData: (): TAction<RCSAEntityPeriodAction, void> => {
    return (dispatch: TDispatch<RCSAEntityPeriodAction>) => {
      return Promise.resolve().then(() => {
        dispatch({
          data: {
            data: DUMMY_DATA,
            total: 20,
          },
          type: RCSAEntityPeriodActionType.GetEntityPeriodName,
        });
      });
    };
  },
};
