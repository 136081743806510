import { TAction, TDispatch } from "../../../types";
import {
  ConfigureAuditTrailsAction,
  ConfigureAuditTrailsActionType,
  ConfigureAuditTrailsModel,
} from "./types";
import { fetchAPI } from "../../../../config/axios";

export const ConfigureAuditTrailsDefault: ConfigureAuditTrailsModel = {
  activityLogs: {
    main: {
      data: [],
      total: 0,
    },
  },
  systemLogs: {
    main: {
      data: [],
      total: 0,
    },
  },
};

// NANTI HAPUS
const DUMMY_DATA: ConfigureAuditTrailsModel["activityLogs"]["main"]["data"] = [
  {
    time: new Date().toString(),
    name: "period",
    description: "updated",
    caused_by: "administrator@admin.com",
  },
  {
    time: new Date().toString(),
    name: "period",
    description: "updated",
    caused_by: "administrator@admin.com",
  },
  {
    time: new Date().toString(),
    name: "period",
    description: "updated",
    caused_by: "administrator@admin.com",
  },
  {
    time: new Date().toString(),
    name: "period",
    description: "updated",
    caused_by: "administrator@admin.com",
  },
  {
    time: new Date().toString(),
    name: "period",
    description: "updated",
    caused_by: "administrator@admin.com",
  },
  {
    time: new Date().toString(),
    name: "period",
    description: "updated",
    caused_by: "administrator@admin.com",
  },
  {
    time: new Date().toString(),
    name: "period",
    description: "updated",
    caused_by: "administrator@admin.com",
  },
  {
    time: new Date().toString(),
    name: "period",
    description: "updated",
    caused_by: "administrator@admin.com",
  },
  {
    time: new Date().toString(),
    name: "period",
    description: "updated",
    caused_by: "administrator@admin.com",
  },
];
// NANTI HAPUS

export const ConfigureAuditTrailsReducer = (
  state: ConfigureAuditTrailsModel = ConfigureAuditTrailsDefault,
  action: ConfigureAuditTrailsAction
): ConfigureAuditTrailsModel => {
  switch (action.type) {
    case ConfigureAuditTrailsActionType.GetActivityLogsMain:
      return {
        ...state,
        activityLogs: { ...state.activityLogs, main: action.data },
      };
    case ConfigureAuditTrailsActionType.GetSystemLogsMain:
      return {
        ...state,
        systemLogs: { ...state.activityLogs, main: action.data },
      };

    default:
      return { ...state };
  }
};

export const ConfigureAuditTrailsCommand = {
  GetActivityLogsMainData: (): TAction<ConfigureAuditTrailsAction, void> => {
    return (dispatch: TDispatch<ConfigureAuditTrailsAction>) => {
      return Promise.resolve().then(() => {
        dispatch({
          data: {
            data: DUMMY_DATA,
            total: 20,
          },
          type: ConfigureAuditTrailsActionType.GetActivityLogsMain,
        });
      });
    };
  },
  GetSystemLogsMainData: (params?: any): TAction<ConfigureAuditTrailsAction, void> => {
    return (dispatch: TDispatch<ConfigureAuditTrailsAction>) => {
      return fetchAPI().get('/api/v1/logs', { params }).then((response) => {
        const data = response.data.data;
        console.log('[][][] cek', data);
        dispatch({
          data: {
            data: data.data,
            total: data.total,
          },
          type: ConfigureAuditTrailsActionType.GetSystemLogsMain,
        });
      });
    };
  },
};
