export interface ConfigureUserDelegationMainDataModel {
  delegated_from: string;
  delegated_from_unit: string;
  delegated_from_position: string;
  role: string[];
  delegated_to_unit: string;
  delegated_to_position: string;
  delegated_to: string;
  delegated_period_start: string;
  delegated_period_end: string;
  delegated_date: string;
  status: string;
}

export interface ConfigureUserDelegationModel {
  main: {
    data: ConfigureUserDelegationMainDataModel[];
    total: number;
  };
}

export enum ConfigureUserDelegationActionType {
  GetUserDelegationMain = "⌘➝Configure➝User➝GetUserDelegationMain",
}

export type ConfigureUserDelegationAction = {
  data: {
    data: ConfigureUserDelegationMainDataModel[];
    total: number;
  };
  type: ConfigureUserDelegationActionType.GetUserDelegationMain;
};
