import { TAction, TDispatch } from "../../../types";
import { CriteriaAction, CriteriaActionType, CriteriaModel } from "./types";

export const CriteriaDefault: CriteriaModel = {
  main: {
    data: [],
    total: 0,
  },
};

// NANTI HAPUS
const DUMMY_DATA: CriteriaModel["main"]["data"] = [
  {
    entity_group: "East Indies Inc.",
    name: "2023",
    description: "Matriks 5x5",
    scale: 4,
    isActive: true,
  },
  {
    entity_group: "North Indies Inc.",
    name: "2023",
    description: "01/10/2023",
    scale: 5,
    isActive: false,
  },
  {
    entity_group: "West Indies Inc.",
    name: "2023",
    description: "01/10/2023",
    scale: 6,
    isActive: false,
  },
  {
    entity_group: "South Indies Inc.",
    name: "2023",
    description: "01/10/2023",
    scale: 6,
    isActive: true,
  },
];
// NANTI HAPUS

export const CriteriaReducer = (
  state: CriteriaModel = CriteriaDefault,
  action: CriteriaAction
): CriteriaModel => {
  switch (action.type) {
    case CriteriaActionType.GetCriteriaMainData:
      return { ...state, main: action.data };

    default:
      return { ...state };
  }
};

export const CriteriaCommand = {
  GetMainData: (): TAction<CriteriaAction, void> => {
    return (dispatch: TDispatch<CriteriaAction>) => {
      return Promise.resolve().then(() => {
        dispatch({
          data: {
            data: DUMMY_DATA,
            total: 20,
          },
          type: CriteriaActionType.GetCriteriaMainData,
        });
      });
    };
  },
};
