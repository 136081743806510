export interface KriTresholdMainDataModel {
  name: string;
  total_kri: number;
}

export interface KriTresholdModel {
  main: {
    data: KriTresholdMainDataModel[];
    total: number;
  };
}

export enum KriTresholdActionType {
  GetKriTresholdMainData = "⌘➝Parameter Setting➝KriTreshold➝GetKriTresholdMainData",
}

export type KriTresholdAction = {
  data: {
    data: KriTresholdMainDataModel[];
    total: number;
  };
  type: KriTresholdActionType.GetKriTresholdMainData;
};
