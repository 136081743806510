export interface ConfigureSettingMainDataModel {
  key: string;
  value: { [key: string]: string };
}

export interface ConfigureSettingModel {
  main: {
    data: ConfigureSettingMainDataModel[];
  };
  // error?: string
  // response?: any;
}

export enum ConfigureSettingActionType {
  GetSettingMain = "⌘➝Configure➝Setting➝GetSettingMain",
  // UpdateSettingMain = "⌘➝Configure➝Setting➝UpdateSettingMain",
  // SetError = "⌘➝Configure➝Setting➝SetError"
}

export interface GetSettingMainAction {
  data: {
    data: ConfigureSettingMainDataModel[];
  };
  type: ConfigureSettingActionType.GetSettingMain;
}

// export interface UpdateSettingMainAction {
//   type: ConfigureSettingActionType.UpdateSettingMain;
//   data: {
//     data: ConfigureSettingMainDataModel[];
//   },
//   response: any;
// }

// export interface SetErrorAction {
//   type: ConfigureSettingActionType.SetError;
//   error: string;
// }

export type ConfigureSettingAction =
  | GetSettingMainAction
  // | UpdateSettingMainAction
  // | SetErrorAction;
