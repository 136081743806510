import { TAction, TDispatch } from "../../../types";
import { ProjectAction, ProjectActionType, ProjectModel } from "./types";

export const ProjectDefault: ProjectModel = {
  main: {
    data: [],
    total: 0,
  },
};

// NANTI HAPUS
const DUMMY_DATA: ProjectModel["main"]["data"] = [
  {
    key_1: "COD-12",
    period: "2023",
    title: "Template Data 1",
    description: "Description Data",
    status: true,
  },
  {
    key_1: "COD-12",
    period: "2023",
    title: "Template Data 2",
    description: "Description Data",
    status: true,
  },
  {
    key_1: "COD-12",
    period: "2023",
    title: "Template Data 3",
    description: "Description Data",
    status: true,
  },
  {
    key_1: "COD-12",
    period: "2023",
    title: "Template Data 4",
    description: "Description Data",
    status: true,
  },
];
// NANTI HAPUS

export const ProjectReducer = (
  state: ProjectModel = ProjectDefault,
  action: ProjectAction
): ProjectModel => {
  switch (action.type) {
    case ProjectActionType.GetProjectMainData:
      return { ...state, main: action.data };

    default:
      return { ...state };
  }
};

export const ProjectCommand = {
  GetMainData: (): TAction<ProjectAction, void> => {
    return (dispatch: TDispatch<ProjectAction>) => {
      return Promise.resolve().then(() => {
        dispatch({
          data: {
            data: DUMMY_DATA,
            total: 20,
          },
          type: ProjectActionType.GetProjectMainData,
        });
      });
    };
  },
};
