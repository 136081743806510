export interface RCSAEntityPeriodMainDataModel {
  groupName: string;
  period: string;
  startDate: Date | string;
  endDate: Date | string;
  description: string;
  status: string;
}

export interface RCSAEntityPeriodModel {
  main: {
    data: RCSAEntityPeriodMainDataModel[];
    total: number;
  };
}

export enum RCSAEntityPeriodActionType {
  GetEntityPeriodName = "⌘➝Parameter Setting➝RCSA Entity Period➝GetEntityPeriodName",
}

export type RCSAEntityPeriodAction = {
  data: {
    data: RCSAEntityPeriodMainDataModel[];
    total: number;
  };
  type: RCSAEntityPeriodActionType.GetEntityPeriodName;
};
