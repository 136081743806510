import { TAction, TDispatch } from "../../../types";
import {
  ConfigureUserDelegationAction,
  ConfigureUserDelegationActionType,
  ConfigureUserDelegationMainDataModel,
  ConfigureUserDelegationModel,
} from "./types";

export const ConfigureUserDelegationDefault: ConfigureUserDelegationModel = {
  main: {
    data: [],
    total: 0,
  },
};

// NANTI HAPUS
const DUMMY_DATA: ConfigureUserDelegationMainDataModel[] = [
  {
    delegated_from: "Agus Sufandi Efendi",
    delegated_from_unit: "Head Officer",
    delegated_from_position: "Head Officer",
    role: ['Administrator', 'Risk VP'],
    delegated_to_unit: "Head Officer",
    delegated_to_position: "Head Officer",
    delegated_to: "Agrafin Afif Krisno Putro",
    delegated_period_start: "28/10/2023",
    delegated_period_end: "31/10/2023",
    delegated_date: "01/10/2023",
    status: "active",
  },
  {
    delegated_from: "Agus Sufandi Efendi",
    delegated_from_unit: "Head Officer",
    delegated_from_position: "Head Officer",
    role: ['Risk VP'],
    delegated_to_unit: "Head Officer",
    delegated_to_position: "Head Officer",
    delegated_to: "Agrafin Afif Krisno Putro",
    delegated_period_start: "28/10/2023",
    delegated_period_end: "31/10/2023",
    delegated_date: "01/10/2023",
    status: "inactive",
  },
  {
    delegated_from: "Agus Sufandi Efendi",
    delegated_from_unit: "Head Officer",
    delegated_from_position: "Head Officer",
    role: ['Administrator', 'Risk VP'],
    delegated_to_unit: "Head Officer",
    delegated_to_position: "Head Officer",
    delegated_to: "Agrafin Afif Krisno Putro",
    delegated_period_start: "28/10/2023",
    delegated_period_end: "31/10/2023",
    delegated_date: "01/10/2023",
    status: "active",
  },
  {
    delegated_from: "Agus Sufandi Efendi",
    delegated_from_unit: "Head Officer",
    delegated_from_position: "Head Officer",
    role: ['Risk VP'],
    delegated_to_unit: "Head Officer",
    delegated_to_position: "Head Officer",
    delegated_to: "Agrafin Afif Krisno Putro",
    delegated_period_start: "28/10/2023",
    delegated_period_end: "31/10/2023",
    delegated_date: "01/10/2023",
    status: "inactive",
  },
  {
    delegated_from: "Agus Sufandi Efendi",
    delegated_from_unit: "Head Officer",
    delegated_from_position: "Head Officer",
    role: ['Administrator', 'Risk VP'],
    delegated_to_unit: "Head Officer",
    delegated_to_position: "Head Officer",
    delegated_to: "Agrafin Afif Krisno Putro",
    delegated_period_start: "28/10/2023",
    delegated_period_end: "31/10/2023",
    delegated_date: "01/10/2023",
    status: "active",
  },
];
// NANTI HAPUS

export const ConfigureUserDelegationReducer = (
  state: ConfigureUserDelegationModel = ConfigureUserDelegationDefault,
  action: ConfigureUserDelegationAction
): ConfigureUserDelegationModel => {
  switch (action.type) {
    case ConfigureUserDelegationActionType.GetUserDelegationMain:
      return { ...state, main: action.data };

    default:
      return { ...state };
  }
};

export const ConfigureUserDelegationCommand = {
  GetUserDelegationMainData: (): TAction<
    ConfigureUserDelegationAction,
    void
  > => {
    return (dispatch: TDispatch<ConfigureUserDelegationAction>) => {
      return Promise.resolve().then(() => {
        dispatch({
          data: {
            data: DUMMY_DATA,
            total: 20,
          },
          type: ConfigureUserDelegationActionType.GetUserDelegationMain,
        });
      });
    };
  },
};
