export interface WorkflowMainDataModel {
  code: string;
  name: string;
  type: string;
  model: string;
  description: string;
  group_code: string;
}

export interface WorkflowModel {
  main: {
    data: WorkflowMainDataModel[];
    total: number;
  };
}

export enum WorkflowActionType {
  GetWorkflowMainData = "⌘➝Parameter Setting➝Workflow➝GetWorkflowMainData",
}

export type WorkflowAction = {
  data: {
    data: WorkflowMainDataModel[];
    total: number;
  };
  type: WorkflowActionType.GetWorkflowMainData;
};
