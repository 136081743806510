export interface AssignmentMainDataModel {
  code: string;
  project: string;
  entity_group: string;
  entity: string;
  workflow: string;
  status: boolean;
}

export interface AssignmentModel {
  main: {
    data: AssignmentMainDataModel[];
    total: number;
  };
}

export enum AssignmentActionType {
  GetAssignmentMainData = "⌘➝Parameter Setting➝Assignment➝GetAssignmentMainData",
}

export type AssignmentAction = {
  data: {
    data: AssignmentMainDataModel[];
    total: number;
  };
  type: AssignmentActionType.GetAssignmentMainData;
};
