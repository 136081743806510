export interface ConfigureUserRiskMgtDataModel {
  name: string;
  username: string;
  email: string;
  unit: string;
  password: string;
  position: string;
  role: string[];
}

export interface ConfigureAllUserDataModel
  extends ConfigureUserRiskMgtDataModel {
  status: "active" | "inactive";
}

export interface ConfigureUserModel {
  riskData: {
    data: ConfigureUserRiskMgtDataModel[];
    total: number;
  };
  allUser: {
    data: ConfigureAllUserDataModel[];
    total: number;
  };
}

export enum ConfigureUserActionType {
  GetUserRiskData = "⌘➝Configure➝User➝GetUserRiskData",
  GetAllUser = "⌘➝Configure➝User➝GetAllUser",
}

export type ConfigureUserAction =
  | {
      data: {
        data: ConfigureUserRiskMgtDataModel[];
        total: number;
      };
      type: ConfigureUserActionType.GetUserRiskData;
    }
  | {
      data: {
        data: ConfigureAllUserDataModel[];
        total: number;
      };
      type: ConfigureUserActionType.GetAllUser;
    };
