export interface UnitGroupEntity {
  ulid: string;
  code: string;
  name: string;
}

export interface UnitEntity {
  ulid: string;
  code: string;
  name: string;
}

export interface ConfigureUnitPIMainDataModel {
  ulid: string;
  id: string;
  name: string;
  pi_name: string;
  entity_group: string;
  entity_name: string;
  entity_ulid: string;
  entity_id: string;
  group_ulid: string;
  description: string;
  status: string;
  group: UnitGroupEntity;
  entity: UnitEntity;
}

export interface ConfigureUnitPIModel {
  main: {
    data: ConfigureUnitPIMainDataModel[];
    total: number;
  };
}

export enum ConfigureUnitPIActionType {
  GetUnitPIMain = "⌘➝Configure➝User➝GetUnitPIMain",
}

export type ConfigureUnitPIAction = {
  data: {
    data: ConfigureUnitPIMainDataModel[];
    total: number;
  };
  type: ConfigureUnitPIActionType.GetUnitPIMain;
};
