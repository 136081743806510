import { TAction, TDispatch } from "../../../types";
import {
  TemplateMaturityAction,
  TemplateMaturityActionType,
  TemplateMaturityModel,
} from "./types";

export const TemplateMaturityDefault: TemplateMaturityModel = {
  main: {
    data: [],
    total: 0,
  },
};

// NANTI HAPUS
const DUMMY_DATA: TemplateMaturityModel["main"]["data"] = [
  {
    name: "Analisis terhadap konteks eksternal",
    type: "GRC",
    isActive: true,
  },
  {
    name: "Perumusan visi, misi, dan nilai-nilai",
    type: "BCM",
    isActive: false,
  },
  {
    name: "Analisis terhadap konteks eksternal",
    type: "GRC",
    isActive: true,
  },
  {
    name: "Perumusan visi, misi, dan nilai-nilai",
    type: "BCM",
    isActive: false,
  },
  {
    name: "Analisis terhadap konteks eksternal",
    type: "GRC",
    isActive: true,
  },
  {
    name: "Perumusan visi, misi, dan nilai-nilai",
    type: "BCM",
    isActive: false,
  },
];
// NANTI HAPUS

export const TemplateMaturityReducer = (
  state: TemplateMaturityModel = TemplateMaturityDefault,
  action: TemplateMaturityAction
): TemplateMaturityModel => {
  switch (action.type) {
    case TemplateMaturityActionType.GetTemplateMaturityMainData:
      return { ...state, main: action.data };

    default:
      return { ...state };
  }
};

export const TemplateMaturityCommand = {
  GetMainData: (): TAction<TemplateMaturityAction, void> => {
    return (dispatch: TDispatch<TemplateMaturityAction>) => {
      return Promise.resolve().then(() => {
        dispatch({
          data: {
            data: DUMMY_DATA,
            total: 20,
          },
          type: TemplateMaturityActionType.GetTemplateMaturityMainData,
        });
      });
    };
  },
};
