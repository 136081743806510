export interface CriteriaMainDataModel {
  entity_group: string
  name: string
  description: string
  scale: number
  isActive: boolean
}

export interface CriteriaModel {
  main: {
    data: CriteriaMainDataModel[];
    total: number;
  };
}

export enum CriteriaActionType {
  GetCriteriaMainData = "⌘➝Parameter Setting➝Criteria➝GetCriteriaMainData",
}

export type CriteriaAction = {
  data: {
    data: CriteriaMainDataModel[];
    total: number;
  };
  type: CriteriaActionType.GetCriteriaMainData;
};
