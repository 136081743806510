import { combineReducers } from "redux";
import {
  ParameterSettingCommand,
  ParameterSettingDefault,
  ParameterSettingReducer,
} from "./parameter-setting/reducer";
import { AppModel } from "./types";
import {
  ConfigureCommand,
  ConfigureDefault,
  ConfigureReducer,
} from "./configure/reducer";

const AppReducer = combineReducers({
  parameterSetting: ParameterSettingReducer,
  configure: ConfigureReducer,
});

const AppDefault: AppModel = {
  parameterSetting: ParameterSettingDefault,
  configure: ConfigureDefault,
};

const AppCommand = {
  parameterSetting: ParameterSettingCommand,
  configure: ConfigureCommand,
};

export { AppReducer, AppDefault, AppCommand };
