export interface TemplateMaturityMainDataModel {
  name: string;
  type: string;
  isActive: boolean;
}

export interface TemplateMaturityModel {
  main: {
    data: TemplateMaturityMainDataModel[];
    total: number;
  };
}

export enum TemplateMaturityActionType {
  GetTemplateMaturityMainData = "⌘➝Parameter Setting➝TemplateMaturity➝GetTemplateMaturityMainData",
}

export type TemplateMaturityAction = {
  data: {
    data: TemplateMaturityMainDataModel[];
    total: number;
  };
  type: TemplateMaturityActionType.GetTemplateMaturityMainData;
};
