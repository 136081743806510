import axios from "axios";
import { API_URL } from "../config/constants";

const fetchAPI = () => {
  const auth = localStorage.getItem("userinfo");
  const userinfo = auth ? JSON.parse(auth) : { token: null };

  const accessToken = userinfo.token;

  const api = axios.create({
    baseURL: API_URL,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Access-Control-Allow-Origin": true,
      "Access-Control-Allow-Credentials": true,
      "Accept-Language": "en",
    },
  });

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        localStorage.removeItem("userinfo");
        // eslint-disable-next-line no-restricted-globals
        location.href = "/login";
      }

      return Promise.reject(error);
    }
  );

  return api;
};

export { fetchAPI };
